import React from 'react'

import Layout from "gatsby-theme-stack/src/layout"
import SEO from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"

const item = (node) => {
  return (
  <div className={"col-lg-3 col-md-4 col-xs-12 mix " + node.category}>
  <div className="portfolio-item">
    <div className="shot-item">
      <img src={node.image} alt="" />  
      <div className="single-content">
        <div className="fancy-table">
          <div className="table-cell">
            <div className="zoom-icon">
              <a className="lightbox" href={"/integration/" + node.code}><i className="lni-eye item-icon"></i></a>
            </div>
            <a href="#">{node.name}</a>
          </div>
        </div>
      </div>
      <div>
      {node.name}
    </div>  
    </div>             
  </div>
</div>)
}


const IntegrationPage = () => {
  const data = useStaticQuery(graphql`
  query integrationPageQuery {
    allChannelJson {
      edges {
        node {
          name,
          code,
          description,
          category,
          image
        }
      }
    }
  }
`)

  return (
  
  <Layout>
    <SEO title="Integration" />

    <section id="portfolios" className="section-padding">
    <h1>Hi from integration page</h1>
    <p>Welcome to integration page</p>
      { /*Container Starts */  }
      <div className="container">
        <div className="section-header text-center">
          <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">Our Works</h2>
          <p>A desire to help and empower others between community contributors in technology <br/> began to grow in 2020.</p>
        </div>
        <div className="row">          
          <div className="col-md-12">
            { /*Portfolio Controller/Buttons */  }
            <div className="controls text-center">
              <a href="javascript:void(0)" className="filter active btn btn-common btn-effect" data-filter="all">
                All 
              </a>
              <a href="javascript:void(0)" className="filter btn btn-common btn-effect" data-filter=".popular">
                Popular 
              </a>     
              <a href="javascript:void(0)" className="filter btn btn-common btn-effect" data-filter=".social">
                Social
              </a>
              <a href="javascript:void(0)" className="filter btn btn-common btn-effect" data-filter=".marketing">
                Marketing
              </a>              
              <a href="javascript:void(0)" className="filter btn btn-common btn-effect" data-filter=".cms">
                CMS
              </a>
              <a href="javascript:void(0)" className="filter btn btn-common btn-effect" data-filter=".crm">
                CRM
              </a>
              <a href="javascript:void(0)" className="filter btn btn-common btn-effect" data-filter=".crm">
                ERP
              </a>   
              <a href="javascript:void(0)" className="filter btn btn-common btn-effect" data-filter=".commerce">
                Commerce
              </a>                          
              <a href="javascript:void(0)" className="filter btn btn-common btn-effect" data-filter=".automation">
                Automation
              </a>               
            </div>
            { /*Portfolio Controller/Buttons Ends */ }
          </div>
        </div>
        <div id="portfolio" className="row">
          {data.allChannelJson.edges.map(e => {
            return item(e.node)
          })}
        </div>
      </div>
      { /*Container Ends */  }
    </section>

  </Layout>)
  }

export default IntegrationPage

  